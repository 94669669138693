.product-vto {
  .cta-vto {
    background-color: $color--black;
    color: $color--white;
  }
  &__name {
    font-size: 13px;
  }
  &__shade-info-name {
    font-size: 14px;
  }
}
