.section-stores {
  .locations_map_panel {
    .gmnoprint {
      div[title^='Pan left'],
      div[title^='Pan down'],
      div[title^='Pan right'],
      div[title^='Pan up'] {
        opacity: 0 !important;
        filter: alpha(opacity=0) !important;
      }
    }
  }
}

.contact-us-page {
  .email-artist-title {
    font-family: $ano-bold_regular-font;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 20px;
    width: 22.5%;
    margin-right: 2%;
  }
}

.toolbar {
  .product--full {
    .product__sticky-wrapper {
      top: 132px;
    }
  }
}

#site-header {
  .site-email-signup {
    .site-email-signup__contents {
      .intro {
        width: 100%;
      }
      .picker-label {
        width: 100%;
      }
    }
  }
}

.site-container {
  .page--spp__custom_palette.MAC_6_PRO_LONGWEAR_PALETTE {
    .product__detail-image {
      display: block;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      background: url('/media/export/cms/products/custom_palettes/mac_sku_888239_640x600_0.jpg')
        no-repeat;
      width: 640px; /* Width of new image */
      height: 600px; /* Height of new image */
      padding-left: 640px; /* Equal to width of new image */
    }
  }
  .product-full {
    &__shade-info {
      margin-bottom: 20px;
    }
    &__details-container {
      .product-full__overview {
        .product-full__installment_price {
          .product__price-installment {
            margin-left: 0;
            width: 100%;
            @include breakpoint($bp--medium-portrait) {
              width: 97%;
            }
          }
        }
      }
      .product-full__installment_price {
        .product__price-installment {
          margin-left: 24px;
          width: 90%;
          @include breakpoint($bp--medium-portrait) {
            width: 94%;
          }
        }
      }
    }
  }
  .page--spp__product {
    .prod_inv_status-3 {
      .product__detail {
        .product__footer {
          .product__inventory-status {
            .coming-soon {
              display: block;
            }
          }
        }
      }
    }
    .product__price {
      width: 100%;
    }
    .product__price-installment {
      width: 100%;
      position: inherit !important;
      top: 8px;
      margin-bottom: 22px !important;
      margin-top: 15px;
      img {
        width: 100px;
        padding-bottom: 5px;
        vertical-align: middle;
        float: left;
      }
      #ap-learnmore {
        float: right;
        display: block;
        border-color: $color--black;
      }
      div {
        float: left;
        margin-top: 10px;
      }
    }
  }
  .product--teaser {
    .product__images {
      margin-bottom: 5px;
    }
    .product__footer {
      .product__size {
        display: none;
      }
      .product_content_fav_mobile {
        float: left;
        width: 99%;
      }
    }
  }
  .sign-in-page {
    position: relative;
    top: 30px;
    padding-bottom: 30px;
  }
  .account-nav {
    position: relative;
    top: 30px;
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 769px) {
  .contact-us-page {
    .contact-nav {
      li {
        width: 31.5%;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  body.fixedpos {
    width: 100%;
  }
}

.prod_inv_status-2 {
  .product__footer {
    .product__inventory-status {
      .temp-out-of-stock {
        display: block;
      }
    }
  }
  .product__bag-bar {
    .temp-out-of-stock {
      display: block;
    }
  }
}

.prod_inv_status-3 {
  .product__footer {
    .product__add-to-bag {
      &.coming-soon {
        display: none !important;
      }
    }
  }
}

.grid--mpp__item {
  .product--teaser {
    .product__footer {
      .product__inventory-status {
        .temp-outof-stock_text,
        .coming-soon_text {
          margin-top: 35px;
          position: absolute;
          right: 0;
        }
        a.notify_me {
          margin: 0;
        }
      }
    }
  }
}

.waitlist-iframe-wrapper {
  width: 95%;
  height: 190px;
  margin: 17px 7px 5px 7px;
}

.tiny-waitlist-overlay {
  .waitlist_header {
    font-size: 20px;
  }
  .waitlist_thankyou_heading {
    font-size: 20px;
  }
  .waitlist_thankyou_message {
    margin-top: 10px;
  }
}

.field {
  .email_input {
    float: left;
    width: 79%;
  }
  .align-r {
    float: left;
    margin-left: 10px;
    .input-btn {
      height: 58px;
    }
  }
  .terms_condition_cont {
    float: right;
    margin: 10px 5px 0 0;
  }
}

.site-footer--top {
  .grid-container--6 .grid--item {
    &:nth-of-type(6n + 2) {
      width: 19.66667%;
      margin-left: 19.66667%;
    }
    &:nth-of-type(6n + 3) {
      width: 19.66667%;
      margin-left: 39.33333%;
    }
    &:nth-of-type(6n + 4) {
      width: 19.66667%;
      margin-left: 59%;
    }
    &:nth-of-type(6n + 5) {
      width: 19.66667%;
      margin-left: 78.66667%;
    }
  }
}

input[type='text'].error {
  border: 1px $color--error solid !important;
}

.error {
  color: $color--error;
}

#shipping-panel {
  .edit-controls {
    .shipping-panel__title a {
      width: 228px;
    }
  }
}

#sign-in-panel {
  #checkout_signin {
    #checkout_signin-submit {
      width: 228px;
    }
  }
  #checkout_signin_new_user {
    #checkout_signin_new_user-submit {
      width: 228px;
    }
  }
}

.site-header {
  .site-header__prefix {
    .site-logo {
      .site-logo__image {
        background-size: auto;
      }
    }
  }
  .site-header__tools {
    .site-email-signup__contents-header {
      line-height: 20px;
      padding: 10px 15px 5px 15px;
    }
    .site-email-signup__contents-form {
      .form-item {
        margin: 0 0 5px 0;
      }
      .picker-label p {
        margin: 0 0 5px 0;
      }
    }
    .block-template-site-my-mac-v1 {
      position: relative;
      right: auto;
      width: 41%;
    }
    .block-template-site-email-signup-au-v1 {
      float: right;
    }
  }
}

.page--spp__product {
  .product--not-shoppable {
    .product__footer {
      .product__inventory-status {
        .coming-soon_text {
          position: initial;
        }
      }
    }
  }
  .prod_inv_status-2 {
    .temp-out-of-stock {
      &.display__notify-me {
        margin: 0 0 65px 0;
      }
    }
  }
}

.product__sticky-wrapper {
  .product__inv-status-item {
    &.temp-out-of-stock {
      background: $color--black;
      font-family: $ano-bold_regular-font;
      font-size: 15px;
      text-decoration: none;
      a {
        &.notify_me {
          background-color: $color--black;
          color: $color--white;
          border-bottom: none;
        }
      }
      .temp-out-of-stock__text {
        display: none;
      }
    }
  }
}

.product__detail {
  .product__images {
    .slick-dots {
      li {
        width: 10px;
        height: auto;
        button {
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
          border-radius: 10px;
          height: 10px;
          width: 10px;
          border: 1px solid $color--gray--black;
        }
      }
      li.slick-active,
      li:hover {
        button {
          background-color: $color--gray--black;
        }
      }
    }
  }
}

.site-footer {
  .afterpay-content,
  .afterpay-content-mb {
    display: none;
  }
}

.custom-grid--bg-img.responsive-container {
  .quicklinks-tout___over_img {
    @include breakpoint($bp--xlarge-down) {
      top: 35% !important;
    }
    @include breakpoint($bp--medium-down) {
      top: 13% !important;
    }
  }
}

.singlerow-blocks-formatter__layout {
  .grid--item.singlerow-blocks-formatter__item {
    .quicklinks-tout___over_img {
      @media (max-width: 1022px) and (min-width: 768px) {
        top: 50% !important;
      }
    }
  }
}

@include breakpoint($bp--large-down) {
  .product--full {
    .product__detail {
      .product__add-to-bag {
        width: 97%;
      }
    }
  }
}

.page--spp-ultra-wide {
  .product--full {
    @include breakpoint($bp--xlarge-up) {
      .shade-picker-dropdown {
        left: 50%;
        padding: 0 7px;
        position: absolute;
        width: 25%;
      }
    }
    @media (min-width: 1281px) {
      .shade-picker-dropdown {
        width: 320px;
        right: 25%;
        left: auto;
      }
    }
  }
}

.page-product {
  .product--full {
    .product__footer {
      .product__add-to-bag {
        display: inline-block;
      }
    }
  }
  .prod_inv_status-1 {
    .shade-picker-dropdown {
      width: 320px;
      right: 25%;
      left: auto;
      padding: 0 7px;
      position: absolute;
      .select2-container {
        margin-left: 7px;
      }
    }
  }
  .product--not-shoppable {
    .product__price-installment {
      display: inline-block;
      margin: 0;
    }
    &.prod_inv_status-7 {
      .product__inventory-status {
        .sold-out {
          display: block;
        }
      }
    }
  }
  .site-container {
    position: relative;
    top: 25px;
  }
}

section {
  &.my-store {
    display: none;
  }
}

.notify-status {
  font-size: 18px;
  max-width: 100%;
  .notify_me {
    margin-#{$ldirection}: 0;
  }
}

.footer-menu {
  &--pc {
    .language {
      display: none;
    }
  }
}

.page-node {
  &.section-bestsellers {
    .site-container {
      &.relativepos {
        .submenu {
          &--sticky {
            position: fixed;
            top: 15%;
          }
        }
      }
    }
  }
}

.viewcart {
  .site-container {
    position: relative;
    top: 30px;
  }
}

.welcome15 {
  .waitlist-iframe-wrapper {
    width: 500px;
    height: 250px;
    margin: 0;
    background: $color--white;
    padding: 40px 15px 30px;
  }
  #cboxLoadedContent {
    overflow: hidden !important;
  }
  #cboxWrapper {
    .icon--remove {
      position: absolute;
      left: 10px;
    }
  }
}
