.site-container {
  .checkout__content {
    #top-viewcart-buttons {
      display: none;
    }
    .guarantee-panel {
      border-bottom: none;
    }
    #sign-in-paypal-panel {
      border-bottom: none !important;
    }
    .shipping-panel {
      .column {
        &:nth-of-type(2n + 1) {
          width: 50%;
          float: left;
          clear: none;
          padding-right: 1em;
        }
        &:nth-of-type(2n + 2) {
          width: 50%;
          float: right;
          margin-left: 0;
          margin-right: 0;
          clear: none;
          padding-right: 0;
        }
        .gift-options {
          word-wrap: break-word;
        }
      }
    }
    .address-form,
    .billing-address-from-fields {
      .form-item.title {
        label {
          display: inline-block;
        }
        label[alt='* Title'] {
          display: inherit;
          margin-bottom: 5px;
        }
      }
      .city-state-zip {
        .postal-code {
          width: 50%;
          float: right;
          margin-left: 0;
          margin-right: 0;
          clear: none;
          padding-right: 0;
          input[type='text'] {
            width: 100%;
            margin-bottom: 0px;
          }
        }
        .state {
          select {
            height: 58px;
            text-decoration: none;
          }
          .select2-choice {
            text-decoration: none;
          }
        }
      }
    }
    .billing-address-form,
    .default-shipping,
    .edit-billing-radio,
    .edit-shipping-radio {
      label {
        display: inline-block;
      }
    }
    .billing-address-from-fields {
      .form-item.title {
        label {
          display: inline-block;
        }
      }
    }
    .product__desc {
      .product__desc-info.loading {
        width: 100%;
        float: none;
        padding-right: 0;
        margin: 0;
        text-transform: lowercase;
      }
    }
    .form-item.title {
      width: 100% !important;
      .picker {
        display: inline-block;
        label {
          display: inline-block;
          &:first-child {
            display: none;
          }
        }
      }
    }
    .qas {
      .qas_container {
        width: 100%;
        padding-right: 0;
        .address_label {
          display: block;
        }
      }
    }
    .default-shipping {
      margin-bottom: 0px;
      label {
        display: inline-block;
      }
    }
    #bottom-viewcart-buttons {
      .viewcart-buttons-panel {
        .content {
          width: 100%;
          .continue-buttons {
            width: 100% !important;
            padding-right: 10%;
            .btn.continue-checkout {
              border-width: 1px;
              border-color: $color--black;
            }
          }
        }
      }
    }
    #continue-btn {
      input[type='submit'] {
        line-height: 60px;
        border: none;
      }
    }
    .cart-header__title--total {
      @include layout($grid--12, 1em) {
        width: 18.33333%;
      }
    }
    .replen_notice {
      display: none;
    }
  }
  #confirmation-page {
    .checkout-header {
      .checkout-header__title {
        width: 100% !important;
        text-align: center;
        right: 0;
        padding-right: 0;
      }
    }
    #registration-panel {
      .messages-container {
        float: none;
        display: block;
      }
      .how-we-will-use-container,
      .checkout-registration__show-password {
        display: none;
      }
    }
  }
  .checkout {
    .right {
      .guarantee-panel {
        display: none;
      }
      .order-summary__shipping-content {
        .form-item.ship-method {
          width: 100%;
        }
      }
    }
    .sign-in-panel {
      border-bottom: 1px solid $color--gray--lightest;
      .messages-container {
        padding: 0;
        background-color: transparent;
        border-width: 0;
        .single-message {
          color: $color--error;
          text-align: left;
        }
      }
    }
    &.shipping_content {
      .checkout__content {
        padding-left: 0px;
      }
    }
    .form-item.ship-method {
      text-align: center;
      width: 90%;
      margin-bottom: 0px;
      .select2-choice {
        height: 30px;
        line-height: 30px;
        .select2-chosen {
          margin: 0px;
          width: 87%;
        }
      }
    }
    select::-ms-expand {
      display: none;
    }
    #continue-btn {
      clear: both;
      input.form-submit {
        width: 228px;
      }
    }
  }
  .checkout__sidebar {
    select::-ms-expand {
      display: none;
    }
    .need-help-panel {
      .text {
        color: $color--full-white;
        line-height: 1.5;
      }
    }
  }
  .checkout-header {
    #top-viewcart-buttons {
      width: 100%;
      border-top: 1px solid $color--gray--lightest;
      display: none;
      .viewcart-buttons-panel {
        padding-top: 1em;
        .content {
          width: 100%;
          .continue-buttons {
            margin-top: 10px;
            padding-right: 0;
            text-align: center;
            .btn.continue-checkout {
              border-width: 1px;
              border-color: $color--black;
            }
          }
        }
      }
    }
    .checkout-header__title {
      width: 100%;
      text-align: center;
      padding-right: 0;
      right: 0;
    }
  }
}

.shipping-panel {
  .edit-shipping-radio,
  .edit-billing-radio {
    .picker {
      display: inline-block;
      label {
        display: inline-block;
      }
    }
  }
}

.phones,
.name-fields {
  .first-name,
  .phone-1 {
    input[type='text'] {
      width: 100%;
    }
  }
}

.checkout {
  .ui-widget-content {
    height: 12em;
    overflow-y: auto;
    font-family: tstar_mono_roundregular, Helvetica, Arial, sans-serif;
    top: 486px;
  }
  .recommended-product-items {
    .description {
      padding-bottom: 50px;
      .info {
        height: auto;
        margin-bottom: 30px;
        .formatted_price {
          position: relative;
          bottom: 0;
          margin-bottom: 10px;
        }
      }
    }
    .addtobag {
      position: absolute;
      bottom: 0;
      input[type='submit'] {
        line-height: 60px;
        border: none;
      }
    }
  }
  .ui-menu-item {
    a.ui-corner-all {
      padding-left: 12px;
      border: none !important;
      display: block;
    }
  }
  .panel select {
    margin-bottom: 0px;
  }
  .ui-corner-all {
    -webkit-border-radius: 0px;
    border-radius: 0px;
  }
  .cart-item__qty-label {
    display: none;
  }
  #shipping-panel,
  .checkout-registration {
    input[type='text'] + label:before,
    input[type='tel'] + label:before,
    input[name='PASSWORD2'] + label:before {
      content: attr(data-required) attr(placeholder);
    }
  }
  .checkout-registration {
    .checkout-password-label {
      display: none;
    }
    input[name='PASSWORD'].js-label-mode + label:before {
      content: attr(alt);
    }
  }
  .checkout-header {
    .continue-shopping {
      display: inline;
    }
  }
  .panel {
    .city-state-zip {
      .form-item.state {
        display: none;
      }
    }
    .billing-address {
      .address1 {
        float: none;
        width: 100%;
        padding-right: 0px;
      }
      .address2 {
        width: 100%;
      }
    }
  }
  .sample-select-button {
    display: none;
  }
  &.confirm {
    .account-nav {
      display: none;
    }
  }
}

.phones,
.city-state-zip {
  .phone-1,
  .phone-2,
  .postal-code {
    input[type='text'].error {
      border-color: $color--error;
    }
  }
}

.section-email-promotions {
  .pc_email_promo_container {
    input[type='text'].error {
      border-color: $color--error;
    }
  }
}

.lpPoweredBy {
  display: none;
}

#shipping-panel {
  &.finished {
    #shipping-address-display,
    #gift-options,
    #billing-address-display {
      float: left;
      width: 33%;
      padding-right: 2%;
      word-wrap: break-word;
    }
  }
}

#offer_code {
  .offer-code__submit {
    input[type='submit'] {
      line-height: 60px;
    }
  }
}

.select2-drop {
  .select2-results {
    .select2-result-label {
      span.select2-match {
        padding-left: 15px;
      }
    }
  }
}

section#promo-panel {
  display: none;
}

@include breakpoint($bp--medium-down) {
  .active-panel-payment,
  .active-panel-shipping {
    .right.checkout__sidebar {
      padding-bottom: 60px;
    }
  }
  .checkout {
    .cart-item {
      a.remove_link {
        position: relative;
        top: 0;
      }
    }
  }
}

.checkout.panel {
  .panel {
    .edit-address {
      .new-address {
        input:required[data-error],
        select:required[data-error],
        input:required.error,
        select:required.error {
          border-color: $color--error;
        }
      }
    }
  }
  .panel.finished {
    display: block;
  }
  .signin-panel {
    header {
      display: block;
    }
  }
}

.adpl {
  input[type='email'].error,
  input[type='tel'].error,
  input[type='password'].error,
  input[type='text'].error {
    color: $color--error !important;
    border-color: $color--error !important;
  }
  input[type='email'] + label:before,
  input[type='tel'] + label:before,
  input[type='password'] + label:before,
  input[type='text'] + label:before {
    content: attr(placeholder);
  }
}

.replenishment__overlay--header {
  font-size: 2rem;
  padding-bottom: 5px;
}

.checkout__sidebar {
  #links-panel {
    margin-top: 1em;
  }
  .tab-bar {
    display: none;
  }
}

.checkout__index-content {
  .cart_item__error--hazmat {
    margin-top: 170px;
  }
}
