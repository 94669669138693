.site-container {
  .mtop {
    #form--errors {
      margin-top: 20px;
    }
  }
  .profile-page__content {
    .birthday_col {
      .form_birthday {
        width: 100%;
        label {
          display: block;
          margin-bottom: 15px;
        }
      }
      .gender_col {
        label {
          display: block;
        }
      }
      .select2-container {
        width: 25% !important;
        margin-right: 5%;
      }
    }
    .profile-info__fieldset {
      .field-container {
        .form_title_width {
          width: 100%;
          .picker {
            display: inline-block;
          }
          .picker-radio.picker {
            &.checked {
              .picker-handle {
                .picker-flag {
                  margin: 2px;
                }
              }
            }
          }
        }
      }
    }
  }
  .account-order-history {
    .past-purchases {
      .past-purchases__item {
        .order-status {
          .order-status {
            margin-left: 0% !important;
          }
        }
      }
    }
  }
  .custom-grid__item {
    .text-header {
      width: 75%;
      border-right: 1px solid white;
    }
    .logo-img {
      top: 12%;
      width: 25%;
      position: relative;
      float: right;
      padding: 0 3%;
      text-align: center;
    }
    .logo-txt {
      text-align: center;
      color: white;
    }
  }
  .past-purchases-page__content {
    .past-purchases {
      .products {
        .product__footer {
          .product-item__unavailable-message {
            float: right;
            height: 40px;
            line-height: 40px;
            margin: 0;
          }
        }
      }
    }
  }
}

#address_form_container {
  margin-bottom: 12px;
  input[type='radio'] {
    -webkit-appearance: radio;
  }
  input[type='checkbox'] {
    -webkit-appearance: checkbox;
  }
  .address-form {
    .address-overlay-shipping {
      select.error,
      input[type='text'].error {
        border: 1px solid $color--error;
      }
      .address_label {
        display: block;
      }
      .address_title_label,
      .address_title_header {
        label {
          display: inline-block;
        }
      }
    }
  }
}

.sign-in-page {
  .pc_signin_container {
    .pc_signin_button {
      width: 50%;
      float: left;
      margin-right: -100%;
      clear: none;
      padding-right: 1em;
      clear: both;
    }
  }
}

.account {
  .ui-widget-content {
    height: 12em;
    overflow-y: auto;
  }
}

.order-details-page {
  .cart-item {
    .cart-item__desc {
      margin-left: 25%;
    }
    .cart-item__price {
      margin-left: 50%;
    }
    .cart-item__qty {
      margin-left: 72%;
    }
  }
  .product-header {
    .price {
      margin-left: 50%;
    }
    .quantity {
      margin-left: 72%;
    }
    .total {
      width: 11.33333%;
    }
  }
}

input[type='text']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.site-header {
  .site-my-mac {
    text-shadow: 0 0 0;
  }
}

.custom-palette__artist {
  .custom-palette__artist-footer {
    bottom: 28px;
  }
}

.product--not-shoppable.product--shaded {
  .product__footer {
    .product__inventory-status {
      .temp-outof-stock_text {
        right: 170px;
        margin-top: 10px;
      }
      .inactive {
        position: absolute;
        right: 0;
      }
    }
  }
}

.product__inventory-status {
  li.product__inv-status-item {
    padding: 0;
  }
}

.picker {
  label.error {
    color: #f14927;
  }
}

.site-header {
  .site-header__tools {
    .site-email-signup__contents-form {
      .form-item {
        .error {
          display: block;
        }
      }
    }
  }
}

.replenish_details {
  #cboxLoadingOverlay,
  #cboxLoadingGraphic {
    position: relative;
  }
}

.replenishment-detail-page {
  .products-list__list {
    .js-rep-details {
      display: none;
    }
  }
}

.order-details-page {
  .cart-item {
    .product-info__spp-status {
      position: absolute;
      bottom: 0;
    }
  }
}
